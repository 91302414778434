import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const CartContext = createContext();
export default CartContext;

const CartProvider = ({ children }) => {

    const [dataBaseProductsDetails, setProducts] = useState([])

    useEffect(() => {
        axios.get('https://api.1book4me.com/product/products')
            .then(res => {
                //console.log(res)
                setProducts(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    const[discountData, setDiscountData] = useState(0)

    useEffect(() => {
        axios.get('https://api.1book4me.com/order/verifyPromotion')
            .then(res => {
                // console.log(res.data)
                setDiscountData(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    const productsInCart = []

    const[cart, setCart] = useState(productsInCart)
    const[total, setTotal] = useState(0.00)

    const addProduct = (product) => {

        product.id = Date.now()
        const updatedCart = [
            ...cart,
            product
        ]

        setCart(updatedCart)
        setTotal(total + parseFloat(product.basePrice))
    }

    const deleteProduct = (productId) => {

        const delProductFromCart = cart.filter(product => product.id !== productId)
        const deletedProduct = cart.filter(product => product.id === productId)

        // setTotal((total-deletedProduct[0]['basePrice']).toFixed(2))
        setTotal((total-deletedProduct[0]['basePrice']))
        setCart(delProductFromCart)
    }

    const navigate = useNavigate()

    const resetCart = () => {
        navigate("/")
        setCart([])
        setTotal(0)
        setDiscountData(0)
    }

    const cartData = {dataBaseProductsDetails, cart, total, discountData, addProduct, deleteProduct, resetCart}

    return (
        <CartContext.Provider value={cartData}>
            {children}
        </CartContext.Provider>
    )
}

export { CartProvider }