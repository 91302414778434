import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Views
// import Home from './routes/Home';
// import Cart from './routes/Cart';
// import Order from './components/orders/Order';
//import PaymentSuccess from './routes/PaymentSuccess';
import PageNoFound from './routes/PageNoFound';
// import Products from './routes/Products';

const Home = lazy(() => import('./routes/Home'))
const Products = lazy(() => import('./routes/Products'))
const Cart = lazy(() => import('./routes/Cart'))
const Order = lazy(() => import('./components/orders/Order'))
const PaymentSuccess = lazy(() => import('./routes/PaymentSuccess'))

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<Router>
				<Routes>
					<Route path="/" element={<App />}>
						<Route index element={<Home />} />
						<Route path="product" element={<Products />} >
							<Route path=":productId" element={<Products />} />
						</Route>
						<Route path="cart" element={<Cart />} />
						<Route path="order" element={<Order />} />
						<Route path="paymentSuccess" element={<PaymentSuccess />} />
						<Route path="*" element={<PageNoFound />} />
					</Route>
				</Routes>
			</Router>
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById('root')
);


