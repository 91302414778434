import React, {Suspense} from 'react'
import { Outlet } from 'react-router'
import Navbar from './Navbar';

const Header = () => {

    return (
        <>
            <Navbar />
            <Suspense fallback={<h3>Loading ...</h3>}>
                <Outlet />
            </Suspense>
            
        </>
    )
}

export default Header
