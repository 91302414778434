import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from '../logo.png'
import useLang from '../hooks/useLang'
import useCart from '../hooks/useCart'

const Navigation = () => {

    const { currentLang, setDesiredLanguage } = useLang()

    const { cart } = useCart()

    const cartIcon = (cart.length !== 0) ? "bi bi-cart-fill" : "bi bi-cart"

    const handleSelect = (eventKey) => setDesiredLanguage(eventKey)

    return (



        <Navbar expand="lg">

            <Navbar.Toggle aria-controls="basic-navbar-nav">
                
            </Navbar.Toggle>
            <h1 className="d-lg-none logo">
                <img className='img-fluid' src={logo} alt={'Начало'} />
            </h1>

            <Navbar.Collapse id="basic-navbar-nav">
                <div className="container justify-content-between opciones-centrado">

                    <h1 className="d-none d-lg-block logo">
                        <Nav.Link href="/" className="navbar-brand">
                            <img src={logo} alt={'Начало'} />
                        </Nav.Link>
                    </h1>
                    <div className="d-flex main-menu">
                        <Nav.Item>
                            <Nav.Link href="/">
                                {currentLang.navbar.home}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={`https://1book4me.com/blog/about-${currentLang.lang}/`} title="За нас" target="_blanc" rel="noopener noreferrer">
                                {currentLang.navbar.about}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://1book4me.com/blog/" target="_blanc" rel="noopener noreferrer">
                                {currentLang.navbar.blog}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={`https://1book4me.com/blog/faqs-${currentLang.lang}`} target="_blanc" rel="noopener noreferrer">
                                <i className="bi bi-question-circle-fill"></i> 
                            </Nav.Link>
                        </Nav.Item>
                        <NavDropdown onSelect={handleSelect} title={currentLang.navbar.language} id="nav-dropdown">
                            <NavDropdown.Item eventKey="bg">Български</NavDropdown.Item>
                            <NavDropdown.Item eventKey="de">Deutsch</NavDropdown.Item>
                            <NavDropdown.Item eventKey="en">English</NavDropdown.Item>
                            {/* <NavDropdown.Item eventKey="es">Español</NavDropdown.Item> */}
                        </NavDropdown>

                        <Nav.Item>
                            <Link to={'/cart'} className="nav-link">
                                <i className={cartIcon} style={{ marginRight: "5px"}}></i> 
                                <Badge bg="transparent">{' '}{cart.length}</Badge>
                            </Link>
                        </Nav.Item>
                        
                    </div>
                </div>
            </Navbar.Collapse>
        </Navbar>

    )
}

export default Navigation