import React, { createContext, useState } from 'react';
import activeLang from '../languages'
import browserLang from '../browserLang'

const LangContext = createContext();
export default LangContext

// Save the value of the user browser language to be applied later as a initial lang
let langBrowser = browserLang()

const LangProvider = ({ children }) => {

    const [currentLang, setLang] = useState(activeLang[langBrowser])

    const setDesiredLanguage = (e) => {
        setLang(activeLang[e])      
    }

    const langData = { currentLang, setDesiredLanguage}

    return (
        <LangContext.Provider value={langData}>
            {children}
        </LangContext.Provider>
    )
}

export {LangProvider}