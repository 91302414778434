import React from 'react'
import Header from './components/Header'
import './scss/app.scss'
import { LangProvider } from './context/langContext'
import { CartProvider } from './context/cartContext'

const App = () => {

  return (
    <div className="App">
      <LangProvider>
        <CartProvider>
          <Header />
        </CartProvider>
      </LangProvider> 
    </div>
  )
}

export default App;
