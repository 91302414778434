const langs = {

    "bg": {
        "lang": "bg",
        "siteTitle": "1book4me | Персонализирани книги за пораснали и непораснали деца",
        "metaDescription": "Нашите персонализирани книги са с уникални истории и илюстрации, и подходящи за всяка възраст и повод",
        "navbar": {
            "home": "начало",
            "about": "за нас",
            "blog": "блог",
            "faqs": "често задавани въпроси",
            "language": "БГ"
        },
        "filter": {
            "label": "Филтър по възраст",
            "all": "Всички книги",
            "child": "Деца",
            "adult": "Възрастни",
        },
        "category": "Филтър по категории: ",
        "cart": {
            "title": "Вашата количка",
            "productName": "Наименование на продукта",
            "price": "Ед. цена",
            "finalToPay": "Крайна сума за количката",
            "total": "Обща стойност на поръчката",
            "btnPay": "Заплати",
            "currency": "лв.",
            "addAnother": "Добави друг продукт",
            "confirmButton": "Потвърди поръчката",
            "modalTitle": "Нашите предложения",
            "modalBody": "Ако желаете да добавите друг продукт, изберете го, и ще бъдете пренасочени към него!",
            "modalConfirm": "Потвърди поръчката",
            "emptyCart": "Вашата количка все още е празна",
            "backToStore": "Към магазина"
        },
        "magic": {
            "title_1": 'МАГИЯ В ТРИ СТЪПКИ',
            "title_2": 'ПРЕЦИЗНО ИЗРАБОТЕНИ, СЪЗДАДЕНИ С МНОГО ЛЮБОВ',
            "text": 'Когато създаваме нашите произведения (като че ли думата „продукти“ не отговаря точно на визията ни за отговорност към потребителя), ние се стремим не просто да напишем стойностна история, но и да я облечем в най-красивите дрехи, в най-пъстроцветната премяна. Наш приоритет е да акцентираме върху красотата и изкуството в ежедневието. Като родители осъзнаваме колко е важно в образованието да присъства културата, колко необходимо е децата ни да се чувстват „в свои води“, когато слушат хубава музика, или застанат пред картина, когато са в театралния салон или пред модерна сграда, връх на съвременната архитектура. Особено в дигиталната ера, в която живеем и отглеждаме „дигитални“ деца, е от изключително значение да възпитаме възприятията им за изкуството, което ще развие най-ценното: тяхното въображение.'
        },
        "whyUs": {
            "title": "ЗАЩО ДА ИЗБЕРЕМ ONE BOOK FOR ME",
            "print": "Високо качество",
            "printDetail": "Нашите книги с оригинални истории и красиви илюстрации са с твърди корици, ръчно шити страници и висококачествен печат.",
            "delivery": "Международен транспорт",
            "deliveryDetail": "За пратки до България транспортът е безплатен, срок на доставка до 7 работни дни. Доставяме поръчки по целия свят с възможност за проследяване на пратката. Срокът и стойността на куриерската услуга зависят от зоната, в която попада страната адресант.",
            "present": "Оригинален подарък",
            "presentDetail": "Персонализираните ни книги са прекрасен и неповторим подарък за тези, които обичате. Те ще бъдат незабравим жест на внимание и обич."
        },
        "testimonial": {
            "title": "За нас казват:",
            "p1": "\"Много сме впечетлени! Великолепна идея! Благодаря ви ;). Сигурна съм, че ще зарадваме много нашата дъщеричка с книжката по случай деня на детето!\" - Д. Б.",
            "p2": "\"Възхитена съм от книжката, създадена от авторите и я намирам за много стойностна и уникална с възможността да бъде персонализирана и с име, и с избор на пола на детето и на косата му. Илюстрациите и историята в книжката са прекрасни, топли, завладяващи и даващи знание за децата. Наистина чудесна възможност за оригинален и много смислен подарък!\"- Т. Б.",
            "p3": "\"Приказни илюстрации и прекрасна идея!\"- Х. Р.",
            "p4": "\"Харесва ми идеята за персонализирана книга. Звучи вдъхновяващо!\"- К. Ч.",
        },
        "contactSection": {
            "title": "СВЪРЖЕТЕ СЕ С НАС, АКО ИМАТЕ ВЪПРОСИ",
            "text": "Можете да зададете Вашите въпроси или да опишете притесненията си на ",
            "text_2": "Ще Ви отговорим с удоволствие във възможно най-кратък срок."
        },
        "footer": {
            "contact": "Контакти:",
            "terms": "Общи условия",
            "privacy": "Политика за поверителност",
        },
        "forms": {
            "clientData": "Данни на поръчителя",
            "fName": "Име и фамилия ( на кирилица )",
            "fEmail": "Имейл",
            "fPhone": "Телефон",
            "receiverData": "Данни на получателя",
            "deliveringData": "Данни свързани с доставката",
            "required": "Задължително поле",
            "nameFormat": "До 30 знака",
            "loading": "Зарежда",
            "submit": "Потвърди",
            "country": "Държава, където ще бъде доставен продукта",
            "state": "Област",
            "phonePrefix": "международен тел. код",
            "city": "Населено място",
            "postcode": "п. код",
            "paymentType": "Разплащателен метод",
            "chooseOne": "Избери",
            "wrongFormat": "Неправилен формат!",
            "terms": " Съгласен съм с Общите условия за ползване",
            "acceptTerms": "Трябва да приемете условията",
            "card": "Кредитна/Дебитна карта"
        },
        "button": {
            "personalize": "Персонализирай",
            "payButton": "Плати",
            "loading": "Зарежда се",
            "submit": "Потвърди"
        },
        "success": {
            "heading": "Поръчката Ви е получена успешно.",
            "p1": "До няколко минути на регистрирания от Вас имейл, ще Ви бъде изпратено писмо с детайлите по поръчката.",
            "p2": "Ако не получите писмото до 15 мин., проверете в папка Спам.",
            "button": "Приключи",
            "orderNumber": "Номер на поръчката: ",
            "totalCart": "Обща цена на продуктите:",
            "deliveringTax": "Доставка:",
            "totalPay": "Общо дължима сума:"
        },
        "descriptionProd": "Описание на продукта",
        "cookies": {
            "text": "Сайтът ни използва COOKIES с цел подобряване на потребителската му фунционалност! Продължавайки в сайта Вие по подразбиране се съгласявате с нашата политика за използване на COOKIES. ",
            "accept": "Приемам",
            "more": "Повече за нашите условия"
        }, 
        "stepper": {
            "one": "Персонализиране",
            "two": "Характеристики",
            "three": "Посвещение",
            "four": "Преглед",
            "five": "Поръчка"
        }
    },
    "en": {
        "lang": "en",
        "siteTitle": "1book4me | Personalized books for all ages",
        "metaDescription": "For all ages",
        "navbar": {
            "home": "home",
            "about": "about us",
            "blog": "blog",
            "faqs": "FAQs",
            "language": "EN"
        },
        "filter": {
            "label": "Filter books by age",
            "all": "Full list of books",
            "child": "Children",
            "adult": "Adults",
        },
        "category": "Filter by category: ",
        "cart": {
            "title": "Cart",
            "productName": "Product's name",
            "price": "Unit price",
            "finalToPay": "Cart amonut",
            "total": "Products total amount",
            "btnPay": "Pay",
            "currency": "€",
            "addAnother": "Add another product",
            "confirmButton": "Submit",
            "modalTitle": "Feautured products",
            "modalBody": "To add a product, just click on the picture!",
            "modalConfirm": "Confirm",
            "emptyCart": "Your cart is empty",
            "backToStore": "Back to the store"
        },
        "magic": {
            "title_1": "MAGIC IN THREE STEPS",
            "title_2": "PRECISELY CRAFTED, CREATED WITH LOVE",
            "text": "When we create our piece of art (as if the word 'products' doesn't quite align with our vision of responsibility towards the user), we strive not only to invent a valuable story but to clothe it in the most beautiful and vibrant attire. Our main goal is to focus on the beauty and artistry in everyday life. As parents, we realize how important it is for the culture to be present in education, how necessary it is for our children to feel in their element when they listen to nice music, when they stand before a painting, when they are in the theatre or in front of a modern building, the pinnacle of contemporary architecture. Especially in the digital era in which we live and raise 'digital' children, it is of utmost importance to cultivate their perception of art, which will nurture the most precious thing: their imagination."
        },
        "whyUs": {
            "title": "Why choose ONE BOOK FOR ME",
            "print": "High quality:",
            "printDetail": "Our books with original stories and beautiful illustrations have hard covers, hand stitched pages and high quality printing.",
            "delivery": "Worldwide deliveries",
            "deliveryDetail": "Shipment to Bulgaria is free. We deliver worldwide, with the ability to track the shipment. The cost and delivery time depend on the zone in which your country is.",
            "present": "An original gift",
            "presentDetail": "Our personalized books are an emotional and unique gift for those you love, an unforgettable gesture of attention, a cherished memory."
        },
        "testimonial": {
            "title": "THEY SAY ABOUT US:",
            "p1": "\"We are very impressed! A great idea! Thank you. I am sure that we will make our daughter very happy with the Children's Day book!\" – D. B.",
            "p2": "\"I am delighted with the book created by the authors and I find it very valuable and unique with the ability to be personalized with the name of the child and a choice of boy or girl and the color of the hair. The illustrations and the story in the book are wonderful, warm, fascinating and giving knowledge to the children. A really great opportunity for an original and very meaningful gift!\" – T. B.",
            "p3": "\"Fairy tale illustrations and a wonderful idea!\" – H.R.",
            "p4": "\"I like the idea of a personalized book. Sounds inspiring!\" - K.C.",
        },
        "contactSection": {
            "title": "CONTACT US IF YOU HAVE ANY QUESTIONS",
            "text": "You can ask your questions or describe your concerns to ",
            "text_2": "We’ll be happy to respond as soon as possible."
        },
        "footer": {
            "contact": "Contacts:",
            "terms": "Terms of use",
            "privacy": "Privacy policy",
        },
        "forms": {
            "clientData": "Who orders:",
            "fName": "Name and surname",
            "fEmail": "Email",
            "fPhone": "Phone number",
            "receiverData": "Who will receive:",
            "deliveringData": "Delivery related data",
            "required": "Required",
            "nameFormat": "max. 30 chars",
            "loading": "Loading",
            "submit": "Submit",
            "country": "Country",
            "state": "Region/State",
            "phonePrefix": "International phone code",
            "city": "Town",
            "postcode": "Zip. code",
            "paymentType": "Payment type",
            "chooseOne": "Select",
            "wrongFormat": "Wrong format!",
            "terms": "I agree the terms of use",
            "acceptTerms": "Must Accept Terms of Service",
            "card": "Credit/debit card"
        },
        "button": {
            "personalize": "Personalize",
            "payButton": "Pay",
            "loading": "Loading",
            "submit": "Submit"
        },
        "success": {
            "heading": "Order received!",
            "p1": "Your order has been successfully received. Within a few minutes, a letter with the details of the order will be sent to your email.",
            "p2": "If you do not receive the letter within 15 minutes, check the Spam folder.",
            "button": "Close",
            "orderNumber": "Order number: ",
            "totalCart": "Total cart amount",
            "deliveringTax": "Delivery costs",
            "totalPay": "Total to pay"
        },
        "descriptionProd": "Product description",
        "cookies": {
            "text": "We use cookies. We may place these for analysis of our visitor data, to improve our website, show personalised content and to give you a great website experience.",
            "accept": "I agree",
            "more": "More information about the cookies we use"
        }, 
        "stepper": {
            "one": "Personalize",
            "two": "Characteristics",
            "three": "Dedication",
            "four": "Preview",
            "five": "Order"
        }
    },
    "es": {
        "lang": "es",
        "siteTitle": "1book4me | Libros personalizados para tu ser querido",
        "metaDescription": "Libros con cuentos originales e ilustraciones únicos, regalo perfecto para cualquier edad u ocasión",
        "navbar": {
            "home": "inicio",
            "about": "Quienes somos",
            "blog": "blog",
            "faqs": "Ayuda",
            "language": "ES"
        },
        "filter": {
            "label": "Filtrar los libros por edad",
            "all": "Todos los libros",
            "child": "Niños",
            "adult": "Aldultos",
        },
        "category": "Categorías: ",
        "cart": {
            "title": "Carrito de compra",
            "productName": "Nombre del producto",
            "price": "Precio/und.",
            "total": "Total del pedido",
            "finalToPay": "Total carrito de compra",
            "btnPay": "Compra",
            "currency": "€",
            "addAnother": "Comprar otro producto",
            "confirmButton": "Confirma el pedido",
            "modalTitle": "Nuestras recomendaciones",
            "modalBody": "Haga click en el producto que desee!",
            "modalConfirm": "Siguiente",
            "emptyCart": "Carrito vacio!",
            "backToStore": "Volver a la tienda"
        },
        "magic": {
            "title_1": 'LA MAGIA EN TRES PASOS',
            "title_2": 'ELABORADOS CON PRECISIÓN, CREADOS CON MUCHO AMOR',
            "text": 'Cuando creamos nuestros frutos de  trabajo (como si la palabra "productos" no reflejara con exactitud nuestra visión de la responsabilidad ante el consumidor), nos esforzamos no sólo por idear una historia que merezca la pena, sino por vestirla con los ropajes más bellos, los ropajes más coloridos. Nuestro claro objetivo es destacar la belleza y el arte en la vida cotidiana. Como padres, nos damos cuenta de lo importante que es que la cultura esté presente en la educación, de lo necesario que es que nuestros hijos se sientan "en sus propias aguas" cuando escuchan buena música, cuando se paran frente a un cuadro, cuando están en el teatro o frente a un edificio moderno, cumbre de la arquitectura contemporánea. Especialmente en la era digital en la que vivimos y criamos a niños "digitales", es crucial alimentar su percepción del arte, que desarrollará lo más preciado: su imaginación.'
        },
        "whyUs": {
            "title": "PORQUÉ ELEGIR ONE BOOK FOR ME",
            "print": "Imprenta de alta calidad",
            "delivery": "Envíos rápidos y seguros",
            "present": "Un regalo muy original",
            "printDetail": "Creamos libros con historias únicas y ilustraciones preciosas, encuadernación de tapa dura cocida a mamo y impresión de alta calidad.",
            "deliveryDetail": "Enviamos nuestros productos a casi cualquier parte del mundo con la opción de seguimento. El tiempo de entrega y el precio del servicio dependen de la zona del destino. Los envios a Bulgaria son gratuitos y en un plazo de 7 días.",
            "presentDetail": "Nuestros libros personalizados son un regalo original y magnifico para vuestros seres más preciados. También son un gesto de atención y amor hacia ellos."
        },
        "testimonial": {
            "title": "Nos comentan así:",
            "p1": "Estamos muy impresionados. Muy buena idea. Gracias ;). Seguro que haremos muy feliz a nuestra hija pequeña con el libro para el Día del Niño! - D.B.",
            "p2": "Estoy encantada con el libro y lo encuentro muy valioso y único por la posibilidad de personalizarlo tanto con un nombre como con la elección del sexo y el pelo del niño. Las ilustraciones y la historia del libro son maravillosas, cálidas, atractivas y fortalecedoras para los niños. Una gran oportunidad para hacer un regalo original y lleno de significado! - T.B.",
            "p3": "Unas ilustraciones fabulosas y una idea maravillosa. - J.R.",
            "p4": "Me gusta la idea de un libro personalizado. Suena inspirador! - K.Ch.",
        },
        "contactSection": {
            "title": "Tienes Preguntas",
            "text": "Restamos a vuestra disposición para resolver cualquier duda, enviando un correo al",
            "text_2": "Les contestamos con mucho gusto en más breve posible."
        },
        "footer": {
            "contact": "Contacta:",
            "terms": "Terminos de uso",
            "privacy": "Política de privacidad",
        },
        "forms": {
            "clientData": "Datos del cliente",
            "fName": "Nombre y Apellidos",
            "fEmail": "Email",
            "fPhone": "Numero de teléfono",
            "receiverData": "Datos del destinatario",
            "deliveringData": "Datos de la entrega",
            "required": "Campo requerido",
            "nameFormat": "Hasta 30 carácteres",
            "loading": "Cargando",
            "submit": "Confirma",
            "country": "País de destino",
            "state": "Provincia",
            "phonePrefix": "prefijo tel.",
            "city": "Población",
            "postcode": "código postál",
            "address": "Dirección completa",
            "addressPlaceholder": "Calle, Plaza, Partida, Nº, etc.",
            "paymentType": "Método de pago",
            "chooseOne": "Eliga",
            "wrongFormat": "Formato incorrecto!",
            "terms": " Estoy de acuerdo con los Terminos y condiciones de uso",
            "acceptTerms": "Deben acceptar los terminos de uso",
            "card": "Tarjeta de credito/debito"
        },
        "button": {
            "personalize": "Personalizar",
            "payButton": "Pagar",
            "loading": "Cargando",
            "submit": "Confirmar"
        },
        "success": {
            "heading": "El pedido se ha realizado con exito!",
            "p1": "En unos instantes recibirán un correo electronicos con los detalles del pedido.",
            "p2": "En el caso de no recibir dicho correo en 15 min, por favor revisen la carpeta de spam y sí tampóco estuviese allí, avisenos para enviarle el correo de nuevo.",
            "button": "Finalizar",
            "orderNumber": "Numero del pedido: ",
            "totalCart": "Total de los Productos",
            "deliveringTax": "Gastos de envio",
            "totalPay": " Total a pagar"
        },
        "descriptionProd": "Descripción del producto",
        "cookies": {
            "text": "Usamos coockies para para mejorar la expiriencia del ususario del nuestro sitio web.",
            "accept": "De acuerdo",
            "more": "Más información sobre las coockies que usamos"
        }, 
        "stepper": {
            "one": "Personalizar",
            "two": "Caracteristicas",
            "three": "Dedicatoria",
            "four": "Previsualizar",
            "five": "Orden"
        }
    },
    "de": {
        "lang": "de",
        "siteTitle": "Personalisierte Bücher für alle Familien",
        "metaDescription": "Bücher ... ",
        "navbar": {
            "home": "Home",
            "about": "Über uns",
            "blog": "Blog",
            "faqs": "Häufige Fragen",
            "language": "DE"
        },
        "filter": {
            "label": "",
            "all": "Alles",
            "child": "Kinder",
            "adult": "",
        },
        "category": "BUCHKATEGORIEN: ",
        "cart": {
            "title": "Warenkorb",
            "productName": "Productname",
            "price": "Einzelpreis",
            "total": "Gesamte Summe",
            "finalToPay": "Endgültiger Warenkorbbetrag",
            "btnPay": "Bezahlen",
            "currency": "€",
            "addAnother": "Weiteres produkt hinzufügen",
            "confirmButton": "Jetzt kaufen",
            "modalTitle": "Unsere Angebote",
            "modalBody": "Wenn Sie ein weiteres Produkt hinzufügen möchten, wählen Sie es aus und Sie werden zu diesem Produkt weitergeleitet!",
            "modalConfirm": "Mit dem Einkauf fortfahren",
            "emptyCart": "Du hast kein Produkte in deinem Warenkorb",
            "backToStore": "Zurück zum Shop"
        },
        "magic": {
            "title_1": "ZAUBER IN DREI SCHRITTEN",
            "title_2": "PRÄZISE GEFERTIGT, MIT VIEL LIEBE ERSTELLT",
            "text": "Wenn wir unsere Werke schaffen (als ob das Wort 'Produkte' nicht ganz mit unserer Vorstellung von Verantwortung gegenüber dem Benutzer übereinstimmt), streben wir nicht nur danach, eine wertvolle Geschichte zu erfinden, sondern sie in das schönste Gewand und in die lebendigste Verwandlung zu kleiden. Unser klares Ziel ist es, die Schönheit und Kunstfertigkeit im Alltag zu betonen. Als Eltern erkennen wir, wie wichtig es ist, dass die Kultur in der Bildung präsent ist, wie notwendig ist, dass unsere Kinder sich wohl fühlen, wenn sie gute Musik hören, ein Gemälde beobachten, im Theater sind oder vor einem modernen Gebäude, dem Höhepunkt zeitgenössischer Architektur stehen. Besonders in der digitalen Ära, in der wir leben und 'Digitale' Kinder großziehen, ist es von größter Bedeutung, ihre Wahrnehmung von Kunst zu kultivieren, die das Kostbarste fördern wird: ihre Vorstellungskraft."
        },
        "whyUs": {
            "title": "WARUM ONE BOOK FOR ME ZU WÄHLEN",
            "print": "Hohe Qualität",
            "printDetail": "Unsere Bücher mit originellen Geschichten und wunderschönen Illustrationen haben Hardcover, handgeheftete Seiten und hochwertigen Druck.",
            "delivery": "Internationaler Transport",
            "deliveryDetail": "Für Sendungen nach Bulgarien ist der Transport kostenlos, die Lieferzeit dauert bis zu 7 Werktage. Wir liefern Bestellungen weltweit und bieten die Möglichkeit an, die Ware auf dem Weg zu Ihnen zu verfolgen. Die Laufzeit und die Versandkosten hängen von dem Lieferungsland ab.",
            "present": "Ein originelles Geschenk",
            "presentDetail": "Unsere personalisierten Bücher sind ein emotionales und einzigartiges Geschenk für eure Lieblingsmenschen. Sie werden eine unvergessliche Geste ihrer Aufmerksamkeit und Liebe sein."
        },
        "testimonial": {
            "title": "WAS MAN ÜBER UNS SAGT:",
            "p1": "Wir sind sehr begeistert! Das ist eine wunderschöne und faszinierende Idee! Vielen Dank für alles! Ich bin mir sicher, dass wir unserer Tochter mit dem Buch eine große Freude anlässlich des Kindertages bereiten werden! ",
            "p2": "Ich bin von dem Buch ziemlich begeistert und finde es sehr wertvoll und einzigartig, dass man das Buch personalisieren kann, indem man den Namen, das Geschlecht und die Haarfarbe des Kindes selbst bestimmen kann. Die Illustrationen und Geschichten in dem Buch sind wunderschön, warmherzig, fesselnd und sehr lehrreich für die Kinder.",
            "p3": "Eine wirklich tolle Gelegenheit für ein originelles und sehr sinnvolles Geschenk! Märchenhafte Illustrationen und eine wunderbare Idee!",
            "p4": "Die Idee für ein personalisiertes Buch gefällt mir sehr! Ich liebe es! Es klingt inspirierend!",
        },
        "contactSection": {
            "title": "KONTAKTIEREN SIE UNS BEI FRAGEN",
            "text": "Sie können ihre Fragen stellen oder ihre Befürchtungen unter ",
            "text_2": "mitteilen. Wir werden Ihnen so schnell wie möglich eine Antwort geben."
        },
        "footer": {
            "contact": "Kontakte:",
            "terms": "Nutzungsbedingungen",
            "privacy": "Datenschutzbestimmungen",
        },
        "forms": {
            "clientData": "Persönlichen Daten",
            "fName": "Name und Nachname",
            "fEmail": "Email",
            "fPhone": "Handynummer",
            "receiverData": "Lieferungsland",
            "deliveringData": "Lieferungsdaten",
            "required": "Erforderliches Feld",
            "nameFormat": "Bis zu 30 Zeichen",
            "loading": "Loading",
            "submit": "Überprüfen",
            "country": "Land",
            "state": "Region",
            "phonePrefix": "Int. Telefonvorwahl",
            "city": "Stadt",
            "postcode": "PLZ. Code",
            "address": "Adresse",
            "addressPlaceholder": "Region/Stadt/PLZ. Code/Adresse",
            "paymentType": "Zahlungsmethode",
            "chooseOne": "Wählen Sie",
            "wrongFormat": "fehlerhaftes Format!",
            "terms": "Ich stimme den Nutzungsbedingungen zu",
            "acceptTerms": "Sie müssen den Nutzungsbedingungen akzeptieren",
            "card": "Credit-/Debitkarte"
        },
        "button": {
            "personalize": "Personalisieren",
            "payButton": "Bezahlen",
            "loading": "Loading",
            "submit": "Submit"
        },
        "success": {
            "heading": "Ihre Bestellung wurde erfolgreich empfangen.",
            "p1": "Innerhalb weniger Minuten wird eine Bestätigung mit den Einzelheiten der Bestellung an Ihre registrierte E-Mail-Adresse gesendet.",
            "p2": " Wenn Sie E-mail nicht innerhalb von 15 Minuten erhalten, prüfen sie Ihren Spam-Ordner.",
            "button": "en aleman -Finalizar",
            "orderNumber": "Order number: ",
            "totalCart": "Zwischensumme",
            "deliveringTax": "Transportkosten",
            "totalPay": "GESAMTBETRAG"
        },
        "descriptionProd": "Product description",
        "cookies": {
            "text": "Unsere Website verwendet COOKIES, um die Benutzerfunktionalität zu verbessern! Indem Sie auf der Website fortfahren, stimmen Sie standardmäßig unserer Richtlinie zur Verwendung von COOKIES zu. ",
            "accept": "Akzeptieren",
            "more": "Mehr zu unseren Konditionen"
        }, 
        "stepper": {
            "one": "Personalisieren",
            "two": "Eigenschaften",
            "three": "Bestimmung",
            "four": "Vorschau",
            "five": "Bestellung"
        }
    }
}


export default langs;