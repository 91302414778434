// Return the users browser language
const browserLang = () => {
    let userLang = navigator.language || navigator.userLanguage;

    const langDelUsuario = userLang.substring(0, 2)

    switch(langDelUsuario) {
        case "bg":
            return "bg"
        case "en":
            return "en"
        case "de":
            return "de"
        case "es":
            return "en"
        default: 
            //return langDelUsuario
            return "bg"
    }
}

export default browserLang